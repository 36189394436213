@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");

html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(12, 8, 24, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.678),
    rgba(12, 10, 22, 0.863)
  );

  --imp-text-color: #c771f1;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(28 19 40), rgb(21 14 36));
  display: grid;
  min-height: calc(100vh - 1px);
  position: relative;
}

.footer {
  background-color: rgb(14, 0, 0);
  bottom: 0 !important;
  padding-top: 15px !important;
  position: absolute;
  clear: both;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.purple {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c0513;
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2d1950;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(178, 121, 216, 0.959);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #1b1a2ea9 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 3rem 2rem !important;
  font-size: 1.2rem !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #181a27 !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 30px !important;
  padding-right: 1em;
}

.engineering {
  height: 20em !important;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.5rem !important;
  border-radius: 5px;
}

@media (max-width: 720px) {
  .nav-link {
    padding: 0.1rem !important;
    border-radius: 5px;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #c95bf1;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.feature-row {
  padding-bottom: 50px;
}

.home-header {
  padding-top: 80px !important;
}

.home-cover-section {
  background: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(26, 14, 70, 0.904)
  );
  position: relative;
  padding-top: 70px !important;
  min-height: 100vh;
}

@media (max-width: 767px) {
  .home-cover-section {
    padding-top: 10px !important;
  }
  .home-header {
    padding-top: 20px !important;
  }
}

.home-about-section {
  background: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(26, 14, 70, 0.904)
  );
  position: relative;
  padding-bottom: 240px !important;
  padding-top: 70px !important;
}

@media (max-width: 767px) {
  .home-about-section {
    padding-bottom: 180px !important;
    padding-top: 10px !important;
  }
}

.home-content {
  padding: 6rem !important;
  color: whitesmoke;
  text-align: left;
  font-weight: 600 !important;
  font-size: 25px;
}

.home-content-bottom {
  position: absolute; 
  bottom: 0; 
  padding: 60px;
  text-align: center;
  background: linear-gradient(to top right, #ff6302, 10%, #c35815);
  width: 70%;
  color: white;
  font-size: 20px;
  box-shadow: 5px 5px 2px 1px rgba(107, 107, 143, 0.2);
}

.home-content-bottom a {
  font-weight: bold;
  font-size: 1em;
  font-style: italic;
  color: #e5b7f7 !important;
}

@media (max-width: 767px) {

  .home-content-bottom {
    width: 100%;
    padding: 40px;
  }
}

@media (max-width: 767px) {
  .home-content {
    padding: 8rem 0 2rem !important;
    font-weight: 300 !important;
    font-size: 16px;
  }
}

.type {
  padding-bottom: 60px; 
  padding-top: 20px; 
  text-align: center
}
.Typewriter__wrapper {
  font-size: 1.3em !important;
  color: #be6adf !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 1.4em !important;
  color: #b562d6 !important;
}

@media (max-width: 767px) {
  .type {
    padding-bottom: 85px; 
    padding-left: 10px;
    text-align: left
  }
  .Typewriter__wrapper {
    font-size: 1.2em !important;
    font-weight: 600 !important;
    position: absolute !important;
    padding-top: 1px;
    padding-bottom: 3px;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

.built_on_img {
  height: 3em !important;
}

@media (max-width: 767px) {
  .built_on_img {
    height: 1.5em !important;
  }
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

@media (max-width: 767px) {
  .heading {
    font-size: 2.1em !important;
    padding-left: 10px !important;
  }
}

.heading-name {
  font-size: .8em;
  text-align: center;
}

@media (max-width: 767px) {
  .heading-name {
    font-size: .95em;
    text-align: left;
    padding: 0 10px 0;
  }
}

.main-name {
  color: #cd5ff8;
}

.sectionImage {
  justify-content: center !important;
  padding-top: 9em !important;
}

.productHeader {
  justify-content: center !important;
  padding-top: 3em !important;
  width: 80%;  
}

@media (max-width: 767px) {
  .sectionImage {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}


.trusted-by-section {
  color: #fff;
  background-color: #262644;
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 50px !important;
}

.trusted-by-section-items {
  justify-content: center !important;
  padding-top: 2em;
}

.trusted-by-section-item {
  color: #1a0101;
  background-color: #fff;
  border: 1px solid #fff;
  text-align: left;
  padding-bottom: 50px;
  padding-top: 50px;
}

.how-it-work {
  color: #fff;
  background-color: #082528;
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 50px !important;
}

.how-it-work-img {
  height: 500px;
  width: 910px;
}

.how-it-work-card {
  width: 18rem; 
  color: #000;
  text-align: left
}

@media (max-width: 767px) {
  .how-it-work-img {
    height:180px;
    width: 310px;
  }
  .how-it-work-card {
    width: 22rem; 
  }
}

.designed-for {
  color: #fff;
  background-color: #2d1950;
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 50px !important;
}

.designed-for-card {
  width: 35rem; 
  color: #000; 
  text-align: justify;
}

@media (max-width: 767px) {
  .designed-for-card {
    width: 22rem; 
  }
}

li {
  list-style-type: circle;
  padding-bottom: 5px;
}

.are-you-ready {
  background-color: #9c5704;
  color: #fff;
  padding-bottom: 70px;
  padding-top: 50px;
}

.get-started-section {
  color: #fff;
  padding-bottom: 70px;
  padding-top: 50px;
}

.get-started-section-content {
  padding: 3rem 0 2rem;
  color: whitesmoke;
  text-align: center;
}

.contact-sales-section {
  position: relative !important;
  padding-top: 100px !important;
  padding-bottom: 30px !important;
  color: white !important;
}

@media (max-width: 767px) {
  .contact-sales-section {
    padding-top: 30px !important;
    padding-bottom: 150px !important;
    color: white !important;
  }
}

.contact-sales {
  padding: 3rem 0 2rem;
  color: whitesmoke;
  text-align: center;
}

.home-contact-sales {
  padding: 15px;
  border: 1px solid #fff;
}

.home-contact-sales:hover {
  background-color: #a588c0 !important;
  cursor: pointer;
}

.home-about-description {
  color: white !important;
  padding-top: 40px !important;
  padding-bottom: 10px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.1em !important;
  text-align: justify;
}

@media (max-width: 767px) {
  .home-about-body {
    text-align: left;
  }
}

.about-founders {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
  height: 290px;
  width: 250px;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.tc {
  display: inline-block !important;
  padding-right: 10px;
  padding-left: 10px;
  text-decoration: none;
  cursor: pointer;
  color: white;
}

.icon-colour {
  color: #700c86 !important;
}

/* --------- */
/* Footer */
/* --------- */

.footer-copyright {
  text-align: center !important;
}

.footer-copyright h2{
  font-size: 1.5em;
  color: white !important;
  margin-bottom: 0.5em !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copyright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #a588c0 !important;
}

.founders {
  font-size: 1.1rem;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}

.whitepaper-image {
  height: 10em !important;
}

.whitepaper {
  justify-content: center !important;
  padding: 10px;
  box-shadow: -.5px -.5px 0 .5px #ccc, inset -1px -1px 0 0 #ccc;
}

.whitepaper-description {
    color: white !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-align: center;
}

.blog-section {
  background-color: rgb(35, 29, 67);
  color: #fff;
  position: relative;
  padding-bottom: 240px !important;
  padding-top: 70px !important;
}

.blog-header {
  font-size: 2.4em !important;
  font-weight: 900;
  color: #be6adf !important;
  text-align: center;
  cursor: pointer;
}

.blog {
  padding-top: 10px;
  font-size: 1.2em !important;
  text-align: center;
  cursor: pointer;
}

.blog-more {
  font-size: 1em !important;
  cursor: pointer;
  text-decoration: underline;
  color: #be6adf;
}

.blog-entry {
  color: white !important;
  padding-top: 30px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.blog-page {
  color: white !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  text-align: left;
}

.blog-page-heading {
  color: white !important;
  text-align: left;
  font-weight: 600;
}

.navbar-toggler-icon {
  background-color: #fff;
  border-radius: 10px;
}

.dropdown-item
.dropdown a {
  display: block;
  padding: 0.5rem;
  color: black;
  text-decoration: none;
  cursor: pointer;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.dropdown a:hover {
  background-color:rgb(231, 228, 228);
}

.mailIcon {
  font-size: 100px;
}


/* Privacy Policy */

.privacy-description {
  color: white !important;
  padding-top: 70px !important;
  padding-bottom: 10px !important;
  text-align: center;
}

.privacy-body {
  padding-top: 20px;
  font-size: 1.1em !important;
  text-align: justify;
}

@media (max-width: 767px) {
  .privacy-body {
    text-align: left;
  }
}

.privacy tr {
  display: block;
  padding: 5px;
}

.privacy_header {
  font-size: 2em;
}

.privacy_sub_header {
  font-size: 1.3em;
}
